import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import Typography from "@material-ui/core/Typography"
import { useFluidUrl } from "../images"
import { ContentContainer } from "../moleculs/content-container"
import { useInView } from "react-intersection-observer"


function TechStackBlock(props) {
  const { classes, technologies } = props
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function setItemClass () {
    if (inView) return `${classes.technologyCard} ${classes.itemAnimation}`
    return classes.technologyCard
  }


  function RenderLogo(logo) {
    const imgSrc = useFluidUrl(logo)
    const imgClass = `${classes.logo} ${classes[logo]}`
      
    return (
      <img src={imgSrc.src} alt="" className={imgClass}/>
    )
  }

  function renderTechnologyItem({title, description, logos}) {
    return (
      <div className={setItemClass()}>
        <div className={classes.text}>
          <Typography
            variant="h3"
            className={classes.title}
            children={title}
          />

          <Typography
            variant="body2"
            className={classes.description}
            children={description}
          />
        </div>

        <div className={classes.logos}>
          {logos.map(RenderLogo)}
        </div>
      </div>
    )
  }

  function renderTechnologies () {
    return (
      <div className={classes.technologiesContainer} ref={ref}>
        {technologies.map(renderTechnologyItem)}
      </div>
    )
  }

  return (
    <ContentContainer ref={sectionRef} customClass={classes.container}>
      <TitleWithDescriptionBlock
        title="See tech stack that we use "
        shouldAnimate={shouldAnimate}
        left={true}
      />
      {renderTechnologies()}
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,

  container: {
    background: "linear-gradient(180deg, #ECF2F6 0%, #FFFFFF 100%);"
  },

  technologiesContainer: {
    marginTop: 100,

    [theme.breakpoints.down("sm")]: {
      marginTop: 50
    },
  },

  technologyCard: {
    display: "flex",
    justifyContent: "space-between",
    opacity: 0,

    [theme.breakpoints.down("sm")]: {
      display: "block",
      ...theme.animations.noAnimation,
    },

    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.text.grey}`,
      padding: "0 0 80px 0",
      marginBottom: 80
    },

    "& > div": {
      width: "40%",

      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },

      "&:last-child": {
        width: "50%",

        [theme.breakpoints.down("sm")]: {
          width: "100%"
        },
      }
    },


    "&:nth-child(1)":{
      animationDelay: "0.3s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.6s",
    },
    "&:nth-child(3)":{
      animationDelay: "0.9s",
    },

    "&:nth-child(4)":{
      animationDelay: "1.2s",
    },

    "&:nth-child(5)":{
      animationDelay: "1.5s",
    },
  },

  text: {
    marginRight: 40,

    [theme.breakpoints.down("sm")]: {
      margin: "0 0 50px 0"
    },

    [theme.breakpoints.down("xs")]: {
      textAlign: "left"
    },
  },

  title: {
    color: theme.palette.text.secondary,
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },

  description: {
    color: theme.palette.text.primary,

    [theme.breakpoints.down("sm")]: {
      textAlign: "left"
    },
  },

  logos: {
    margin: "-15px -12px",
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between"
    },

    [theme.breakpoints.down("xs")]: {
      margin: 0,
      justifyContent: "flex-start"
    },
  },

  logo: {
    padding: "15px 12px",
    maxWidth: "100%"
  },

  techNode: {
    maxWidth: 105
  },

  techReact: {
    maxWidth: 191
  },

  techJS: {
    maxWidth: 64
  },
  techTS: {
    maxWidth: 64
  },
  techSQL: {
    maxWidth: 122
  },
  techMongo: {
    maxWidth: 238
  },
  techUnion: {
    maxWidth: 68
  },
  techFigma: {
    maxWidth: 65
  },
  techPS: {
    maxWidth: 67
  },
  techAI: {
    maxWidth: 67
  },
  techWebflow: {
    maxWidth: 245
  },
  techAws: {
    maxWidth: 102
  },
  techGithub: {
    maxWidth: 71
  },
  techCircleCI: {
    maxWidth: 100
  },
  techLinux: {
    maxWidth: 57
  },
  techDocker: {
    maxWidth: 75
  },
  techHetzner: {
    maxWidth: 172
  },
  techJira: {
    maxWidth: 390,

    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%"
    },
  },

  techIcon1: {
    maxWidth: 72
  },
  techIcon2: {
    maxWidth: 72
  },
  techGA: {
    maxWidth: 187
  },
  techHotjar: {
    maxWidth: 229
  },
  techMixpanel: {
    maxWidth: 190
  },
  itemAnimation: {
    ...theme.animations.itemFadeInUp,

    "&:nth-child(1)":{
      animationDelay: "0.3s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.6s",
    },
    "&:nth-child(3)":{
      animationDelay: "0.9s",
    },

    "&:nth-child(4)":{
      animationDelay: "1.2s",
    },

    "&:nth-child(5)":{
      animationDelay: "1.5s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
})

// eslint-disable-next-line
TechStackBlock = withStyles(styles)(TechStackBlock)

export { TechStackBlock }
