import React from "react"
import { Layout } from "../components/organism/layout"
import Seo from "../components/seo"
import { OurProjectsBlock } from "../components/organism/our-projects-block"
import { Constants } from "../constants"
import { TechStackBlock } from "../components/organism/tech-stack-block"
import { ContactsBlock } from "../components/organism/contacts-block"

const IndexPage = ({ location }) => {
  const {
    ourProjectsBlock,
    softwareDevelopmentHeader,
    ourProjects,
    technologies,
    softwareDevelopmentCallToActionBlock
  } = Constants
  return (
    <Layout
      background="softwareDevelopmentBg"
      callToActionButton
      {...softwareDevelopmentHeader}
    >
      <Seo title="Custom Software Development" location={location} />

      <main>
        <TechStackBlock technologies={technologies} />
        <OurProjectsBlock {...ourProjectsBlock} ourProjects={ourProjects} />
        <ContactsBlock
          callToActionBlockInfo={softwareDevelopmentCallToActionBlock}
        />
      </main>
    </Layout>
  )
}
export default IndexPage
