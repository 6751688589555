import React from "react"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { useFluidUrl } from "../images"

function OurProjectItem(props) {
  const { classes, imgName, title, description, link = '', externalClass = '' } = props
  const imgSrc = useFluidUrl(imgName)
  const containerClass = `${classes.projectContainer} ${externalClass}`

  return (
    <div className={containerClass}>
      <div className={classes.preview}>
        <img src={imgSrc.src} alt=""/>
      </div>
      <div>
        <div className={classes.text}>
          <Typography
            variant="h3"
            className={classes.title}
            children={title}
          />

          <Typography
            variant="body1"
            className={classes.description}
            children={description}
          />
        </div>
      </div>
    </div>
  )
}

const styles = theme => ({
  projectContainer: {
    display: "flex",
    margin: "0 -15px",

    [theme.breakpoints.down("sm")]: {
      display: "block"
    },

    "& > div": {
      width: "50%",
      padding: "0 15px",

      [theme.breakpoints.down("sm")]: {
        width: "auto"
      },
    },

    "&:not(:last-child)": {
      marginBottom: 150,

      [theme.breakpoints.down("sm")]: {
        marginBottom: 90
      },
    },

    "&:nth-child(2n)": {
      flexDirection: "row-reverse",
    }
  },
  text: {
    padding: "0 50px",

    [theme.breakpoints.down("sm")]: {
      padding: 0
    },
  },
  preview: {
    "& img": {
      width: "100%",
      maxWidth: 570,
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
  title: {
    marginBottom: 30,
  },
  description: {
    marginBottom: 30,
  },
  link: {
    color: "#FFA600",
    fontSize: 18,
    lineHeight: "24px",
    position: "relative",
    paddingRight: 25,
    display: "inline-block"
  },
  linkArrow: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(0, -46%)",

    "& svg": {
      display: "block"
    }
  }
})

// eslint-disable-next-line
OurProjectItem = withStyles(styles)(OurProjectItem)

export { OurProjectItem }
