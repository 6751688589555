import React, { useRef } from "react"
import { TitleWithDescriptionBlock } from "../moleculs/title-with-description-block"
import { withStyles } from "@material-ui/core"
import useShouldAnimate from "../../hooks/useShouldAnimate"
import useScrollPosition from "../../hooks/useScrollPosition"
import { OurProjectItem } from "../moleculs/our-project-item"
import { ContentContainer } from "../moleculs/content-container"
import { useInView } from "react-intersection-observer"


function OurProjectsBlock(props) {
  const { classes, title, description, ourProjects } = props
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const scrollPosition = useScrollPosition()
  const sectionRef = useRef()
  const sectionPosition = sectionRef?.current?.offsetTop
  const shouldAnimate = useShouldAnimate(sectionPosition, scrollPosition)

  function setItemClass () {
    if (inView) return `${classes.itemBlock} ${classes.itemAnimation}`
    return classes.itemBlock
  }

  function renderProjectItem ({imgName, title, description, link}, key) {
    return (
      <OurProjectItem
        imgName={imgName}
        title={title}
        description={description}
        link={link}
        key={key}
        externalClass={setItemClass()}
      />
    )
  }

  function renderProjects () {
    return (
      <div ref={ref} className={classes.projectsBlock}>
        {ourProjects.map(renderProjectItem)}
      </div>
    )
  }

  return (
    <ContentContainer ref={sectionRef}>
      <div>
        <TitleWithDescriptionBlock
          title={title}
          description={description}
          shouldAnimate={shouldAnimate}
          left={true}
        />
        {renderProjects()}
      </div>
    </ContentContainer>
  )
}

const styles = theme => ({
  ...theme.animations.fadeInUp,
  container: {
    backgroundColor: theme.palette.text.white,
    ...theme.defaultHorizonContainer,

    "& > div": {
      padding: "70px 0",
    },
    [theme.breakpoints.down("sm")]: {

    },
    [theme.breakpoints.down("xs")]: {

      "& > div": {
        paddingTop: "91px",
      },
    },
  },
  projectsBlock: {
    marginTop: 100,

    [theme.breakpoints.down("sm")]: {
      marginTop: 50
    },
  },
  itemBlock: {
    opacity: 0,
    animationDelay: "0.35s",

    "&:nth-child(1)":{
      animationDelay: "0.35s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.7s",
    },
    "&:nth-child(3)":{
      animationDelay: "1.05s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
  itemAnimation: {
    ...theme.animations.itemFadeInUp,

    "&:nth-child(1)":{
      animationDelay: "0.35s",
    },
    "&:nth-child(2)":{
      animationDelay: "0.7s",
    },
    "&:nth-child(3)":{
      animationDelay: "1.05s",
    },

    [theme.breakpoints.down("sm")]: {
      ...theme.animations.noAnimation,
    },
  },
})

// eslint-disable-next-line
OurProjectsBlock = withStyles(styles)(OurProjectsBlock)

export { OurProjectsBlock }
