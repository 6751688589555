import React from "react"
import { Header } from "./header"
import { Footer } from "./footer"

const Layout = props => {
  const {
    callToActionButton,
    background,
    children,
    title,
    description,
    backgroundColor,
    desktopButtonText,
    mobileButtonText,
    saas = false,
    isHome = false,
  } = props

  return (
    <>
      <Header
        background={background}
        callToActionButton={callToActionButton}
        title={title}
        description={description}
        backgroundColor={backgroundColor}
        desktopButtonText={desktopButtonText}
        mobileButtonText={mobileButtonText}
        saas={saas}
        isHome={isHome}
      />
      {children}
      <Footer />
    </>
  )
}
export { Layout }
