import React from "react"
import { withStyles } from "@material-ui/core"
import { Navigation } from "./navigation"
import { HeaderTitleAndDescription } from "../moleculs/header-title-and-description"
import { CallToActionButton } from "../moleculs/call-to-action-button"
import { GatsbyImageBackground } from "../images"
import { ContentContainer } from "../moleculs/content-container"

function Header(props) {
  const {
    classes,
    background,
    callToActionButton,
    title,
    description,
    onlyBackground,
    customHeaderContent,
    backgroundColor,
    desktopButtonText,
    mobileButtonText,
    saas,
    isHome,
  } = props

  const backgroundClassName = classes.rectangle

  const disabledAnimationClassNames = saas
    ? `${backgroundClassName} ${classes.saasDisableAnimation}`
    : backgroundClassName

  const headerClass = setHeaderClass()

  return (
    <header className={headerClass}>
      <GatsbyImageBackground
        className={disabledAnimationClassNames}
        name={background}
      >
        <div className={classes.navigationContainer}>
          <Navigation isHeader />
        </div>
        {renderHeaderContent()}
      </GatsbyImageBackground>
    </header>
  )

  function renderHeaderContent() {
    if (customHeaderContent) return customHeaderContent

    const titleClasses = isHome
      ? `${classes.titlePre} ${classes.titleAnimation}`
      : classes.titleAnimation

    return (
      <ContentContainer customClass={classes.headerContentWrapper}>
        <HeaderTitleAndDescription
          title={title}
          description={description}
          customAnimationClass={titleClasses}
        />
        {renderButton()}
      </ContentContainer>
    )
  }

  function renderButton() {
    if (!callToActionButton) return null

    return (
      <div className={classes.buttonContainer}>
        <CallToActionButton
          desktopText={desktopButtonText}
          mobileText={mobileButtonText}
          withText={isHome}
          text="There’s a single team available at the moment"
        />
      </div>
    )
  }

  function setHeaderClass() {
    if (backgroundColor === "gray") return classes.header

    if (backgroundColor === "white") return classes.whiteHeader

    return classes.header
  }
}

const styles = theme => {
  return {
    ...theme.animations.fadeIn,
    ...theme.animations.zoomIn,
    header: {
      backgroundColor: theme.palette.background.lightGray,
    },
    whiteHeader: {
      backgroundColor: theme.palette.background.white,
    },
    navigationContainer: {
      opacity: 0,
      paddingTop: "0",
      animationDelay: ".4s",
      animation: "$fadein .4s ease-in-out forwards",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "34px",
      },
      [theme.breakpoints.down("xs")]: {
        opacity: 1,
        animation: "none",
        paddingBottom: "0px !important",
      },
    },
    rectangle: {
      height: "100vh",
      width: "100%",
    },
    triangle: {
      width: "100%",
      height: "950px",
      position: "relative",
      clipPath: "polygon(100% 0, 100% 65%, 0 100%, 0 100%, 0 0)",
      "-webkit-clip-path": "polygon(100% 0, 100% 65%, 0 100%, 0 100%, 0 0)",
      "&:before": {
        animation: "$zoomin 5s ease-in-out forwards",
      },
      [theme.breakpoints.down("md")]: {
        height: ({ isHome }) => (isHome ? "1050px" : "850px"),
        clipPath: "polygon(100% 0, 100% 65%, 0 100%, 0 100%, 0 0)",
        "-webkit-clip-path": "polygon(100% 0, 100% 65%, 0 100%, 0 100%, 0 0)",
      },
      [theme.breakpoints.down("sm")]: {
        height: ({ isHome }) => (isHome ? "920px" : "768px"),
        clipPath: "polygon(100% 0, 100% 77.5%, 0 100%, 0 100%, 0 0)",
        "-webkit-clip-path": "polygon(100% 0, 100% 78%, 0 100%, 0 100%, 0 0)",
      },
      [`@media screen and (max-width: 720px)`]: {
        height: ({ isHome }) => (isHome ? "1050px" : "768px"),
      },
      [theme.breakpoints.down("xs")]: {
        height: ({ isHome }) => (isHome ? "760px" : "564px"),
        clipPath: "polygon(100% 0, 100% 90%, 0 100%, 0 100%, 0 0)",
        "-webkit-clip-path": "polygon(100% 0, 100% 90%, 0 100%, 0 100%, 0 0)",
      },
      ["@media screen and (max-width: 375px)"]: {
        height: ({ isHome }) => (isHome ? "910px" : "564px"),
        clipPath: "polygon(100% 0, 100% 92%, 0 100%, 0 100%, 0 0)",
        "-webkit-clip-path": "polygon(100% 0, 100% 92%, 0 100%, 0 100%, 0 0)",
      },
    },
    titleAnimation: {
      opacity: 0,
      animationDelay: ".6s",
      animation: "$fadein .4s ease-in-out forwards",
    },
    titlePre: {
      maxWidth: 770,
      whiteSpace: "pre-line",

      [theme.breakpoints.down("md")]: {
        whiteSpace: "normal",
      },
    },
    saasDisableAnimation: {
      "&:before": {
        animation: "none",
      },
    },
    bookTeamContainer: {
      display: "flex",
      flexBasis: 0,
      padding: "10px 10px 10px 20px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "16px",
      backgroundColor: "rgba(16, 10, 35, 0.3)",
    },
    buttonContainer: {},
    headerContentWrapper: {
      "& > div": {
        paddingTop: '5vh',
        paddingBottom: '7vh'
      }
    }
  }
}

// eslint-disable-next-line
Header = withStyles(styles)(Header)

export { Header }
