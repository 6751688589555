import React from "react"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core"

function HeaderTitleAndDescription({
  classes,
  title,
  description,
  descriptionComponent,
  customAnimationClass = null,
}) {
  return (
    <div
      className={`${classes.titleAndDescriptionContainer} ${customAnimationClass}`}
    >
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      {renderDescription()}
    </div>
  )

  function renderDescription() {
    if (descriptionComponent) return descriptionComponent
    return (
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
    )
  }
}

const styles = theme => {
  return {
    title: {
      textAlign: "left",
      textIndent: "0",
      color: theme.palette.text.white
    },
    description: {
      marginTop: "30px",
      textAlign: "left",
      whiteSpace: "pre-line",
      color: theme.palette.text.white,

      [theme.breakpoints.down("sm")]: {
        marginTop: "29px",
        marginBottom: "29px",
        maxWidth: "81%",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "29px",
        maxWidth: "100%",
        fontSize: "20px",
        lineHeight: "30px",
      },
    },
    titleAndDescriptionContainer: {
      opacity: 0,
      paddingTop: "23px",
      display: "flex",
      flexDirection: "column",
      maxWidth: 770,

      [theme.breakpoints.down("xs")]: {
        padding: "0px 0px 5px",
      },
    },
  }
}

// eslint-disable-next-line
HeaderTitleAndDescription = withStyles(styles)(HeaderTitleAndDescription)

export { HeaderTitleAndDescription }
